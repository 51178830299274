import React from 'react';

function Work({ data }) {
  return (
    <div className="flex flex-row justify-center text-center sm:whitespace-wrap md:whitespace-nowrap lg:whitespace-wrap lg:justify-between lg:text-start">
      <div className="flex flex-col">
        <h1 className="text-white text-xl">{data.company_name}</h1>
        <h2 className="text-highlight-dark text-lg">{data.title}</h2>
      </div>
      <p className="text-white text-lg collapse lg:visible whitespace-nowrap">
        {data.dates_worked}
      </p>
    </div>
  );
}

export default Work;
