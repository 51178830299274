import React from 'react';
import Card from './Card';
import Work from './Work';
import SeeMore from './SeeMore';
import data from '../../assets/data/experience.json';

function WorkCard() {
  return (
    <Card>
      <div className="flex flex-col">
        <h1 className="text-center text-white text-3xl">
          Professional Experience
        </h1>
      </div>
      <div className="flex flex-col gap-y-5">
        {data.work_experiences
          .slice(0)
          .reverse()
          .map((workData) => (
            <Work key={workData.id} data={workData} />
          ))}
      </div>
      <SeeMore href="https://www.linkedin.com/in/jibrana/" />
    </Card>
  );
}

export default WorkCard;
