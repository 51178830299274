import React, { useState } from 'react';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ContactPageIcon from '@mui/icons-material/ContactPage';

import Card from './Card';

function Links() {
  const [currentHover, setCurrentHover] = useState('');
  return (
    <Card>
      <div className="flex">
        <div className="flex justify-center items-center text-lg w-[20%] bg-accent-dark rounded-full text-center text-highlight-dark">
          <h1
            style={{
              opacity: currentHover === '' ? 0 : 1
            }}
            className="transition duration-300 ease-in-out"
          >
            {currentHover}
          </h1>
        </div>
        <div className="flex justify-between w-[80%] ml-10 pl-10 pr-10">
          {[
            {
              text: 'Resume',
              icon: <ContactPageIcon fontSize="large" />,
              url: 'https://drive.google.com/file/d/1rUKv7eZE41gmyv9LmFJ2T_dDgvPIoIR6/view?usp=sharing'
            },
            {
              text: 'LinkedIn',
              icon: <LinkedInIcon fontSize="large" />,
              url: 'https://www.linkedin.com/in/jibrana/'
            },
            {
              text: 'GitHub',
              icon: <GitHubIcon fontSize="large" />,
              url: 'https://github.com/itsjayway'
            },
            {
              text: 'Contributions',
              icon: <HandshakeIcon fontSize="large" />,
              url: 'https://github.com/stars/itsjayway/lists/contributions'
            }
          ].map((link) => (
            <a
              className="text-accent-dark hover:text-highlight-dark transition duration-300 ease-in-out"
              key={link.text}
              href={link.url}
              onMouseEnter={() => setCurrentHover(link.text)}
              onMouseLeave={() => setCurrentHover('')}
              target="_blank"
              rel="noreferrer"
            >
              {link.icon}
            </a>
          ))}
        </div>
      </div>
    </Card>
  );
}

export default Links;
