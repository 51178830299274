import Chip from './Chip';

function Project({ data }) {
  return (
    <div className="flex flex-col gap-y-3">
      <a
        className="flex flex-col hover:scale-[1.01] transition"
        href={data.href}
        target="_blank"
        rel="noreferrer"
      >
        <h1 className="overline text-white text-2xl">{data.projectName}</h1>
        <h1 className="text-highlight-dark text-lg">
          {data.topics.join(', ')}
        </h1>
        <h1 className="text-white text-2xl">{data.description}</h1>
      </a>
      <div className="flex flex-nowrap gap-x-2">
        {data.technologies.map((technology) => (
          <Chip
            key={`${data.projectName}-${technology.label}`}
            label={technology.label}
            color={technology.color}
          />
        ))}
      </div>
    </div>
  );
}

export default Project;
