import { React } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import New from './components/New';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <New />
          }
        />
        <Route path="/experimental" element={<New />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
