import React from 'react';

import MainCard from './new/MainCard';
import ProjectCard from './new/ProjectCard';
import WorkCard from './new/WorkCard';
import Links from './new/Links';

function New() {
  return (
    <>
      {/* Main div */}
      <div className="flex w-[100vw] min-h-[100vh] bg-gradient-to-t from-primary-dark to-accent-dark justify-center items-center">
        <div className="flex flex-col lg:flex-row lg:items-start gap-y-10 lg:gap-x-10 p-10">
          <div className="flex flex-col min-h-[90vh] items-center gap-y-10">
            <MainCard />
            <WorkCard />
          </div>
          <div className="flex flex-col min-h-[90vh] items-center gap-y-10">
            <ProjectCard />
            <Links />
          </div>
        </div>
      </div>
    </>
  );
}

export default New;
