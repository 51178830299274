import React from 'react';

function Chip({ label, color, useUrl = true }) {
  /**
   * This comment is necessary for tailwind to apply dynamic style
   * bg-yellow-300
   * bg-blue-300
   * bg-orange-300
   * bg-purple-300
   * bg-gray-300
   * bg-green-300
   * hover:bg-yellow-500
   * hover:bg-blue-500
   * hover:bg-orange-500
   * hover:bg-purple-500
   * hover:bg-gray-500
   * hover:bg-green-500
   * border-yellow-700
   * border-blue-700
   * border-orange-700
   * border-purple-700
   * border-gray-700
   * border-green-700
   * text-yellow-800
   * text-blue-800
   * text-orange-800
   * text-purple-800
   * text-green-800
   *
   */
  const className = `font-sans bg-${color}-300 hover:bg-${color}-500 text-lg text-${color}-800 border-${color}-700 text-center rounded-full border-2 pl-2 pr-2 pt-1 pb-1 whitespace-nowrap`;
  const titleLabel = `${label.charAt(0).toUpperCase()}${label.slice(1)}`;
  let urlParam = label;
  if (label === 'react') {
    urlParam = 'javascript';
  }
  return (
    <a
      href={`https://github.com/itsjayway?tab=repositories&q=&type=source&language=${urlParam}&sort=`}
      target="_blank"
      className={className}
      rel="noreferrer"
    >
      {titleLabel}
    </a>
  );
}

export default Chip;
