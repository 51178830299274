import React from 'react';
import { motion } from 'framer-motion';

import Card from './Card';

function MainCard() {
  const title = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.3
      }
    }
  };

  const text = {
    hidden: { y: 5, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <>
      <Card>
        <div className="flex flex-row justify-center items-center gap-x-5">
          <img
            className="w-[8rem] h-[8rem] rounded-full"
            src="https://avatars.githubusercontent.com/u/70596906?v=4"
            alt="avatar"
          />
          <motion.ul
            className="flex flex-col h-[100%] justify-center"
            variants={title}
            initial="hidden"
            animate="visible"
          >
            <motion.li
              variants={text}
              className="text-2xl xl:text-4xl text-white"
            >
              Jibran A.
            </motion.li>
            <motion.li
              variants={text}
              className="text-lg xl:text-2xl text-white"
            >
              Software Engineer
            </motion.li>
          </motion.ul>
        </div>
        {/* <motion.ul
          variants={chips}
          initial="hidden"
          animate="visible"
          className="flex flex-row gap-x-2"
        >
          {[
            {
              label: 'python',
              color: 'yellow'
            },
            {
              label: 'react',
              color: 'blue'
            },
            {
              label: 'java',
              color: 'orange'
            },
            {
              label: 'php',
              color: 'gray'
            }
          ].map((chip) => (
            <motion.li variants={chipItem}>
              <Chip label={chip.label} color={chip.color} />
            </motion.li>
          ))}
        </motion.ul> */}
      </Card>
    </>
  );
}

export default MainCard;
